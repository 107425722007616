<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-menu
        v-model="menu"
        close-on-content-click
        lazy
        transition="scale-transition"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="month"
          prepend-inner-icon="event"
          readonly
        />
        <v-date-picker
          v-model="month"
          :max="thisMonth"
          min="2020-04"
          type="month"
        />
      </v-menu>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :loading="loading"
      :expand="expand"
      :rows-per-page-items="[50, 100, 200]"
      :search="search"
      item-key="msisdn"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr
          :active="props.selected"
          @click="toggleSelection(props)"
        >
          <td class="text-xs-left">
            {{ props.item.msisdn }}
          </td>
          <td class="text-xs-center">
            {{ props.item.tier }}
          </td>
          <td class="text-xs-right">
            {{ props.item.reload }}
          </td>
        </tr>
      </template>
      <template #expand="props">
        <v-layout column>
          <v-flex xs10>
            <v-card-text color="darken-5">
              <v-data-table
                :headers="detailHeaders"
                :items="props.item.list"
                item-key="tier"
                class="elevation-1 px-3"
                disable-initial-sort
                hide-actions
              >
                <template #items="thisProps">
                  <tr>
                    <td class="text-xs-left">
                      {{ thisProps.item.entry }}
                    </td>
                    <td class="text-xs-left">
                      {{ thisProps.item.source }}
                    </td>
                    <td class="text-xs-right">
                      {{ thisProps.item.reload }}
                    </td>
                    <td class="text-xs-right">
                      {{ thisProps.item.incentive }}
                    </td>
                    <td class="text-xs-left">
                      {{ thisProps.item.remark ? thisProps.item.remark : '-' }}
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-flex>
        </v-layout>
      </template>
      <template
        v-if="!alert"
        #footer
      >
        <tr>
          <td>&nbsp;</td>
          <td class="text-xs-right">
            Total:
          </td>
          <td class="text-xs-right info">
            RM {{ totalReload }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime } from 'luxon'
import { createGetParams } from '@/rest'

const title = 'Subscriber Summary'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Incentives', disabled: false, to: '/incentives',
        },
        {
          text: 'RI', disabled: false, to: '/royalty_incentives',
        },
        {
          text: title, disabled: true,
        },
      ],
      detailHeaders: [
        {
          text: 'Entry',
          align: 'left',
          sortable: true,
          value: 'entry',
        },
        {
          text: 'Source',
          align: 'left',
          sortable: true,
          value: 'source',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: true,
          value: 'reload',
        },
        {
          text: 'RI (RM)',
          align: 'right',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Remark',
          align: 'Left',
          sortable: true,
          value: 'remark',
        },
      ],
      expand: false,
      headers: [
        {
          text: 'Msisdn',
          align: 'left',
          sortable: true,
          value: 'msisdn',
        },
        {
          text: 'Tier',
          align: 'center',
          sortable: true,
          value: 'tier',
        },
        {
          text: 'Reload (RM)',
          align: 'right',
          sortable: true,
          value: 'reload',
        },
      ],
      items: [],
      loading: false,
      menu: false,
      month: null,
      search: '',
      selected: [],
      title: title,
      totalIncentive: 0.00,
      totalReload: 0.00,
    }
  },
  computed: {
    thisMonth: function () {
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
      return now.toFormat('yyyy-LL')
    },
  },
  watch: {
    month: function (val) {
      val && this.getReloadBySubscriber(val)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.month = now.toFormat('yyyy-LL')
  },
  methods: {
    getReloadBySubscriber: function (month) {
      const param = { month }
      this.loading = true
      this.alert = false
      this.items = []
      this.totalIncentive = this.totalReload = '0.00'
      this.$rest.get('getReloadBySubscriber.php', createGetParams(param))
        .then(function (response) {
          this.items = response.data.item
          this.totalReload = response.data.totalReload
          this.totalIncentive = response.data.totalIncentive
          if (response.data.message) {
            this.alertMessage = response.data.message
            this.alertType = 'info'
            this.alert = true
          }
          this.loading = false
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alertType = 'error'
          this.alert = true
          this.loading = false
        })
    },
    getRoyaltyIncentive: function (msisdn) {
      const param = { time: this.month, msisdn }
      const callback = (element) => element.msisdn === msisdn
      const index = this.items.findIndex(callback)
      this.items[index].list.length < 1 && this.$rest.get('getRoyaltyIncentive.php', createGetParams(param))
        .then(function (response) {
          this.items[index].list = response.data.item
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          console.log(message)
        })
    },
    toggleSelection: function (item) {
      item.selected = !item.selected
      item.expanded = !item.expanded
      if (this.selected.length > 1) this.selected.shift()
      if (item.expanded) {
        this.getRoyaltyIncentive(item.item.msisdn)
      }
    },
  },
}
</script>
